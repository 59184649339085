<template>
    
        <button class="btn btn-sm btn-danger ng-scope margin-r-5 hidden-xs hidden-sm" ng-class="small? 'btn-block': 'hidden-xs hidden-sm'" ng-click="endChat(chat)" ng-disabled="chat.chatEndRunning()">
            <i class="fa fa-fw fa-close" ng-hide="chat.chatEndRunning()"></i>
           
            End
        </button> 
        <div class="btn-group margin-r-5" uib-dropdown dropdown-append-to-body auto-close="outsideClick">
            <button type="button" class="btn btn-primary btn-sm"  v-on:click=" forwardBtn = !forwardBtn " >
                <i class="fa fa-mail-forward"></i>
                Forward
            </button>
            <ul class="dropdown-menu dropdown-menu-btn-option dropdown-forward dropdown-menu-right" v-bind:class = "forwardBtn?'show':''" v-if="forwardBtn" v-click-outside="closeEvent">
               <li role="menuitem"><a href="#">Remove From My Queue</a></li>
               <li role="menuitem"><a href="#">Assign To Agent…</a>
                    <div class="bg-info text-muted text-center">
                    <i>No agents available</i>
                    </div>
                </li>
              </ul>
        </div>
        <div class="btn-group margin-r-5" uib-dropdown dropdown-append-to-body auto-close="outsideClick">
            <button class="btn btn-primary btn-sm" title="Change Topic"  v-on:click=" changeTopicBtn = !changeTopicBtn "> 
                <i class="fa fa-arrows-alt"></i>
                Change Topic
            </button>
              <ul class="dropdown-menu dropdown-menu-btn-option dropdown-forward change-topic dropdown-menu-right" v-bind:class = "changeTopicBtn?'show':''" v-if="changeTopicBtn" v-click-outside="closeEvent">
                <li>
                    <Multiselect
                          v-model="value"
                          placeholder="Select topic"
                          label="name"
                          :groups="true"
                          :searchable="true"
                          :options="[
                            {
                              label: 'Customer Success',
                              options:  [
                                  { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                  { value: '_Feedback', name: '_Feedback',level:'2'},
                                  { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                  { value: '_Spam', name: '_Spam',level:'2'},
                                  { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                  { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                  { value: 'Return', name: 'Return',level:'2'},
                                  { value: 'Return', name: 'Return',level:'2'},
                                  { value: 'Cancel', name: 'Cancel',level:'2'},
                              ],
                            }
                          ]"
                        >
                        <template v-slot:option="{ option }">
                          <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                        </template>
                      </Multiselect>
                </li>
              </ul>  
        </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'


    export default {
      name: 'ChatButtonsComponents',
       components: {
         Multiselect
      },
      data: function () {
        return {
          shownotificationdropdown: false, 
          sendBtn: false,
          forwardBtn:false,
          myvar : '',
          selectAgent : false,
          forwardSegment : false,
          forwardExternalSegment : false,
          saveBtn: false,
          changeTopicBtn: false
          
        }
      },
      events: {
        closeEvent: function () { alert("dd");
         
        }
      },
      methods: {
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }

    }
</script>